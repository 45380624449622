import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import Pll,{ logos } from 'react-pll';


const Skills = () => {
    const { skills } = useContext(PortfolioContext);
    const [isDesktop, setIsDesktop] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    console.log(logos)
    useEffect(() => {
        if (window.innerWidth > 769) {
          setIsDesktop(true);
          setIsMobile(false);
        } else {
          setIsMobile(true);
          setIsDesktop(false);
        }
      }, []);

      return (
        
          
        <section id="projects" >
            <Container>
            <div className="project-wrapper">
                <Title title="Programmation"/>
                <Row style={{display:'flex', justifyContent: 'center'}}>
                {skills.map((skill)=> {
                    return(
                        
                        <div id='skills'>
                            <Col lg={true} sm={4} >
                            <Fade
                                left={isDesktop}
                                bottom={isMobile}
                                duration={1000}
                                delay={500}
                                distance="30px"
                            >
                            <p id="skills">
                            
        
                            <img  id="logos" src={"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/"+skill+"/"+skill+"-original.svg"} />
          
                            <br/>
                            {skill}
                            </p>
                            
                            </Fade>
                            </Col>
                        </div>
                       
                    )
                }
                )
                
                }
                 </Row>
            </div> 
            </Container>
        </section>

      )
      
}
export default Skills;