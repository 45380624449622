import React, { useContext, useEffect, useState } from 'react';
import Bounce  from 'react-reveal/Bounce';
import Tilt from 'react-tilt';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import Pll,{ logos } from 'react-pll';


const Softwares = () => {
    const { softwares } = useContext(PortfolioContext);
    const [isDesktop, setIsDesktop] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    console.log(logos)
    useEffect(() => {
        if (window.innerWidth > 769) {
          setIsDesktop(true);
          setIsMobile(false);
        } else {
          setIsMobile(true);
          setIsDesktop(false);
        }
      }, []);

      return (
        
          
        <section id="softwareSection" >
            <Container>
            <div className="project-wrapper">
                <Title title="Logiciels et Outils"/>
                <Row style={{display:'flex', justifyContent: 'center'}}>
                {softwares.map((soft)=> {
                    return(
                        
                        <div id='skills'>
                            <Col lg={true} sm={4} >
                            <Bounce 
                                cascade 
                                left={isDesktop}
                                bottom={isMobile}
                                duration={1000}
                                delay={500}
                               
                            >
                            
                            
        
                            <a href={soft.link}  target="_blank">
                            <img id="softs" src={soft.logo} title={soft.name} />
                            </a>
                            
                            
                            </Bounce>
                            </Col>
                        </div>
                       
                    )
                }
                )
                
                }
                 </Row>
            </div> 
            </Container>
        </section>

      )
      
}
export default Softwares;