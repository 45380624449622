import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import L from '../../assets/logosplace.png'
import LightSpeed from 'react-reveal/LightSpeed';
import Slide from 'react-reveal/Slide';
import Flip from 'react-reveal/Flip';
import Bounce from 'react-reveal/Bounce';



const Experience = () => {
    const { experience } = useContext(PortfolioContext);
    const [isDesktop, setIsDesktop] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    
    useEffect(() => {
        if (window.innerWidth > 769) {
          setIsDesktop(true);
          setIsMobile(false);
        } else {
          setIsMobile(true);
          setIsDesktop(false);
        }
      }, []);

      return (
        
          
        <section id="expSection" >
            <Container>
            <div className="project-wrapper">
                <Title title="Expériences PROFESSIONNELLES"/>
               
                {experience.map((exp)=> {
                    
                    
                    return(
                        
                        <div id='xp'>
                            
                            <div >

                            <LightSpeed
                                left={true}
                                bottom={isMobile}
                                duration={1000}
                                delay={300}
                                distance="30px"
                            >
                            
                            <img id="watermark"  src={exp.logo} />
                             </LightSpeed>
                            
                           

                            </div>
                           
                             <div>
                                 <Row  >
                                     <Col>
                                     
                                        <p>
                                        <Fade
                                      left={isDesktop}
                                      bottom={isMobile}
                                      delay={1000}
                                      distance="100px"
                                      >
                                        {exp.type}
                                        </Fade>
                                        </p>
                                     
                                     </Col>
                                     <Col md="auto">
                                        <h2>
                                            <LightSpeed
                                            left={isDesktop}
                                            bottom={isMobile}
                                            duration={1000}
                                            delay={300}
                                            distance="30px"
                                            >
                                            {exp.name}

                                            </LightSpeed>
                                        
                                    
                                    
                                        </h2>
                                     </Col>
                                     <Col>
                                     <Fade
                                      right={isDesktop}
                                      bottom={isMobile}
                                      delay={1000}
                                      distance="100px"
                                      >
                                        <p>
                                        {exp.date}
                                        </p>
                                        
                                     </Fade>
                                     </Col>
                                 </Row>

                                 <Row style={{flex: 1 , justifyContent: "center"}}>
                                     <Col md={8}>
                                     <Flip 
                                     top={isDesktop}
                                     bottom={isMobile}
                                     delay={1000}
                                     >
                                        <p id="xpText">
                                            {exp.description}
                                        </p>
                                     </Flip>
                                     </Col>
                                 </Row>

                             </div>
                             <Fade Top
                             delay={600}
                             >
                             <h1 id='taskFont'> Tâches réalisées</h1>
                             </Fade>
                             
                             {exp.tasks.map((task)=>{
                                 return (
                                     <Bounce bottom>
                                         <h1 id="task">{task}</h1>
                                     </Bounce>
                                     
                                 )
                             })}
                             
                        </div>
                        
                       
                    )
                }
                )
                
                }
                 
            </div> 
            </Container>
        </section>

      )
      
}
export default Experience;